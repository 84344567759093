import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { AuthContext } from "../../providers/AuthProvider";
import AddUpdate from "./components/AddUpdate";
import api from "../../utils/api";
import "./Cidades.css";
import Modals from "../../components/Modal";
import { Box, CircularProgress } from "@mui/material";

const Cidades = () => {
  const { reload, setReload } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/cidades");

      if (data) {
        setClients(data);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
      setReload(false);
    }
  };

  useEffect(() => {
    getClients();
  }, [reload]);

  return (
    <div className="containerFuncionarios">
      <div className="containerHeaderCidades">
        <div className="title">Cidades</div>

        <AddUpdate Button={<div className="button">Adicionar</div>} />
      </div>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            padding: "40px",
            marginTop: "60px",
          }}
        >
          <CircularProgress sx={{ color: "#187648" }} />
        </Box>
      ) : (
        <div className="containerList">
          {clients?.map((item, idx) => {
            return (
              <div
                key={idx}
                className={`card ${idx % 2 !== 0 && "colorBack"}`}
                style={{ padding: "10px" }}
              >
                <div className="item">{item?.nome}</div>
                <div className="item">{item?.estado}</div>
                <Modals
                  buttons={<DeleteIcon sx={{ color: "#d22c38" }} />}
                  title="APAGAR CIDADE"
                  id={item.id}
                  rota="cidades"
                  label={
                    <div>
                      Tem certeza de que deseja apagar a cidade{" "}
                      <strong>{item?.nome}</strong>? ao confirmar, essa ação não
                      podera ser desfeita.
                    </div>
                  }
                />
                <AddUpdate
                  Button={
                    <div className="button">
                      <EditIcon />
                    </div>
                  }
                  value={item}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Cidades;
