import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import "./SumaryFolha.css";

const SumaryFolha = () => {
  const { user } = useContext(AuthContext);
  const [clients, setClients] = useState([]);
  const [citys, setCitys] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [loading, setLoading] = useState(true);

  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/funcionarios/filters/${user?.id}`, { params: {situacao: 1 }});
      const { data: dataCities } = await api.get("/cidades");
      if (data && dataCities) {
        setClients(data);
        setCitys(dataCities);
      }
    } catch (error) {
      console.error(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const filteredClients = clients.filter(
    (client) => client.cidade.nome === selectedCity.nome
  );

  const agrupadosPorProjeto = filteredClients.reduce((acc, item) => {
    const projeto = item.projeto;
    if (!acc[projeto]) {
      acc[projeto] = [];
    }
    acc[projeto].push(item);
    return acc;
  }, {});

  function patronal(salario, dados) {
    const valorServico = Number(salario);
    const patronal = dados.cidade.patronal === "sim" && dados.tipoPagamento !== "mei" ? valorServico * 0.2 : 0;
    return patronal;
  }

  const resultado = Object.entries(agrupadosPorProjeto).map(
    ([projeto, pessoas]) => ({
      projeto,
      pessoas,
      totalSalario: pessoas.reduce(
        (total, pessoa) => total + Number(pessoa.salario),
        0
      ),
      inss: pessoas.reduce(
        (total, pessoa) =>
          total + calcularValorLiquido(Number(pessoa.salario), pessoa),
        0
      ),
      patronal: pessoas.reduce(
        (total, pessoa) => total + patronal(Number(pessoa.salario), pessoa),
        0
      ),
    })
  );

  function formatToBRL(value) {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  function calcularValorLiquido(salario, dados) {
    const valorServico = Number(salario);
    const inss =
      dados?.cidade?.inss === "sim" && dados.tipoPagamento !== "mei"
        ? valorServico * 0.11
        : 0;
    const baseCalculo =
      inss > 564.8 ? valorServico - inss : valorServico - 564.8;

    let irrf = 0;
    if (
      baseCalculo > 4664.68 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.275 - 896.0; // 27,5%
    } else if (
      baseCalculo > 3751.05 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.225 - 662.77; // 22,5%
    } else if (
      baseCalculo > 2826.65 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.15 - 381.44; // 15%
    } else if (
      baseCalculo > 2259.2 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.075 - 169.44; // 7,5%
    }

    const patronal = dados.cidade.patronal === "sim" && dados.tipoPagamento !== "mei" ? valorServico * 0.2 : 0; // Outros descontos
    return inss + irrf + patronal;
  }

  return (
    <div className="summary-container">
      <div className="headerSumay">
        <div className="title">RESUMO DA FOLHA</div>
        <FormControl sx={{ ml: 2, minWidth: 150 }} size="small">
          <InputLabel id="city-label">Cidade</InputLabel>
          <Select
            labelId="city-label"
            label="Cidade"
            onChange={(e) => setSelectedCity(e.target.value)}
            defaultValue=""
          >
            {citys.map((city) => (
              <MenuItem key={city.id} value={city}>
                {city.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="summary-table">
        <div className="summary-header">
          <div className="header-item">ORD</div>
          <div className="header-item">PROJETOS</div>
          <div className="header-item">SALÁRIO BRUTO</div>
          <div className="header-item">INSS (RF)</div>
          <div className="header-item">GESTÃO TÉCNICA</div>
          <div className="header-item">CUSTO FINAL</div>
        </div>

        {!selectedCity && resultado.length <= 0 && (
          <div className="row-item" style={{ marginTop: "20px" }}>
            Nenhuma informação
          </div>
        )}
        {selectedCity && resultado.length <= 0 && (
          <div className="row-item" style={{ marginTop: "20px" }}>
            Nenhuma informação
          </div>
        )}

        {resultado.map((item, idx) => (
          <div className="summary-row" key={idx}>
            <div className="row-item">{idx + 1}</div>
            <div className="row-item">{item.projeto}</div>
            <div className="row-item">{formatToBRL(item.totalSalario)}</div>
            <div className="row-item">{formatToBRL(item.inss)}</div>
            <div className="row-item">
              {formatToBRL(
                Number(item.totalSalario) * (Number(selectedCity.gt) / 100)
              )}
            </div>
            <div className="row-item">
              {formatToBRL(
                Number(item.totalSalario) +
                  Number(item.patronal) +
                  Number(item.totalSalario) * (Number(selectedCity.gt) / 100)
              )}
            </div>
          </div>
        ))}

        {selectedCity && resultado.length > 0 && (
          <div className="summary-row totals">
            <div className="row-item">Total</div>
            <div className="row-item"></div>
            <div className="row-item">
              {formatToBRL(
                resultado.reduce(
                  (acc, item) => acc + Number(item.totalSalario),
                  0
                )
              )}
            </div>
            <div className="row-item">
              {formatToBRL(
                resultado.reduce((acc, item) => acc + Number(item.inss), 0)
              )}
            </div>
            <div className="row-item">
              {formatToBRL(
                resultado.reduce(
                  (acc, item) =>
                    acc +
                    Number(item.totalSalario) * (Number(selectedCity.gt) / 100),
                  0
                )
              )}
            </div>
            <div className="row-item">
              {formatToBRL(
                resultado.reduce(
                  (acc, item) =>
                    acc +
                    Number(item.totalSalario) +
                    Number(item.patronal) +
                    Number(item.totalSalario) * (Number(selectedCity.gt) / 100),
                  0
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SumaryFolha;
