import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import EditIcon from "@mui/icons-material/Edit";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { visuallyHidden } from "@mui/utils";
import AddUpdate from "./AddUpdate";
import Modals from "../../../components/Modal";
import ReciboModal from "./ReciboModal";
import Switch from "@mui/material/Switch";
import * as XLSX from "xlsx";
import ListaFuncionariosPDF from "../../../components/ListaFuncionariosPDF";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import api from "../../../utils/api";
import { toast } from "react-toastify";
import moment from "moment";
import { AuthContext } from "../../../providers/AuthProvider";

function getNestedValue(obj, path) {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
}

function getComparator(order, orderBy) {
  return (a, b) => {
    const aValue = getNestedValue(a, orderBy);
    const bValue = getNestedValue(b, orderBy);

    if (order === "desc") {
      return bValue < aValue ? -1 : 1;
    } else {
      return aValue < bValue ? -1 : 1;
    }
  };
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    user,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: "nome", numeric: false, disablePadding: true, label: "Nome" },
    { id: "cpf", numeric: true, disablePadding: false, label: "CPF" },
    ...(user.typeUser === "admin" || user.typeUser === "Supervisor"
      ? [
          {
            id: "tipoPagamento",
            numeric: false,
            disablePadding: false,
            label: "Vinculo",
          },
          {
            id: "salario",
            numeric: false,
            disablePadding: false,
            label: "Salario",
          },
          {
            id: "salario",
            numeric: true,
            disablePadding: false,
            label: "Salario líquido",
          },
        ]
      : []),
    {
      id: "projeto",
      numeric: false,
      disablePadding: false,
      label: "Projetos",
    },
    { id: "", numeric: false, disablePadding: false, label: "" },
    { id: "", numeric: false, disablePadding: false, label: "" },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {(user.typeUser === "admin" || user.typeUser === "Supervisor") && (
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                textAlign: "center",
                display: "flex",
                // justifyContent: "center",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({
  numSelected,
  onSearchChange,
  downloadCSV,
  user,
  filteredRows,
}) {
  function isMobileDevice() {
    return window.innerWidth <= 900;
  }
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selecionados
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: isMobileDevice() && "column",
            alignItems: isMobileDevice() && "center",
            width: "100%",
          }}
        >
          <Typography variant="h6" id="tableTitle" component="div">
            Colaboradores
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: isMobileDevice() ? "flex-end" : "center",
              flexDirection: isMobileDevice() && "column",
              gap: isMobileDevice() && "10px",
              width: isMobileDevice() && "100%",
              marginTop: isMobileDevice() && "10px",
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Buscar por nome"
              onChange={(e) => onSearchChange(e.target.value)}
              sx={{ width: isMobileDevice() ? "100%" : "200px" }}
            />
          </div>
        </Box>
      )}
      {(user.typeUser === "admin" || user.typeUser === "Supervisor") && numSelected > 0 && (
        <>
          <ListaFuncionariosPDF funcionarios={filteredRows} />
          <ReciboModal filteredRows={filteredRows} />
          <Tooltip
            title="Baixar"
            onClick={() => downloadCSV()}
            style={{ fontSize: "15px" }}
          >
            <IconButton>
              XLS
              <FileDownloadIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  downloadCSV: PropTypes.func.isRequired,
};

export default function EnhancedTable({ clients, cities, user, loading, cityId }) {
  const { setReloadColab } = React.useContext(AuthContext);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("nome");
  const [selected, setSelected] = React.useState([]);
  const [dense, setDense] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [listCargos, setListCargos] = React.useState([]);
  const [editingSalaryId, setEditingSalaryId] = React.useState(null);
  const [editingSalary, setEditingSalary] = React.useState("");
  const [editingProjeto, setEditingProjeto] = React.useState("");
  const [loadingSave, setLoadingSave] = React.useState(false);

  const handleSalaryEdit = (id, currentSalary, projeto) => {
    setEditingSalaryId(id);
    setEditingSalary(currentSalary);
    setEditingProjeto(projeto);
  };

  const handleSalaryChange = (event) => {
    setEditingSalary(event.target.value);
  };

  const getListItens = async () => {
    try {
      const { data: cargos } = await api.get("/cargos");

      if (cargos) {
        setListCargos(cargos);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Não foi possível carregar a lista de clientes.");
    } finally {
    }
  };

  React.useEffect(() => {
    getListItens();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  // Filtra os clientes com base no termo de busca
  const filteredClients = clients.filter((client) => {
    const matchesName = client.nome
      ?.toLowerCase()
      .includes(searchTerm?.toLowerCase());
    return matchesName;
  });

  const visibleRows = React.useMemo(
    () => [...filteredClients].sort(getComparator(order, orderBy)),
    [filteredClients, order, orderBy]
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  function calcularValorLiquido(salario, dados) {
    const valorServico = Number(salario);

    const iss =
      dados?.cidade?.iss === "sim" && dados.tipoPagamento !== "mei"
        ? valorServico * 0.05
        : 0; // ISS (5%)
    const inss =
      dados?.cidade?.inss === "sim" && dados.tipoPagamento !== "mei"
        ? valorServico * 0.11
        : 0; // INSS (11%)

    let baseCalculo = inss > 564.8 ? valorServico - inss : valorServico - 564.8;

    let irrf = 0;

    if (
      baseCalculo > 4664.68 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.275 - 896.0; // 27,5%
    } else if (
      baseCalculo > 3751.05 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.225 - 662.77; // 22,5%
    } else if (
      baseCalculo > 2826.65 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.15 - 381.44; // 15%
    } else if (
      baseCalculo > 2259.2 &&
      dados?.cidade?.irrf === "sim" &&
      dados.tipoPagamento !== "mei"
    ) {
      irrf = baseCalculo * 0.075 - 169.44; // 7,5%
    }

    const outrosDescontos = 0; // Outros descontos
    const valorLiquido = valorServico - iss - inss - irrf - outrosDescontos;

    return valorLiquido;
  }

  function removeDotsKeepHyphen(input) {
    return input ? input.replace(/\./g, "") : ""; // Remove todos os pontos, ou retorna string vazia se input for null/undefined
  }

  function downloadXLS() {
    // Filtrar as linhas selecionadas
    const filteredRows = visibleRows.filter((item) =>
      selected.includes(item.id)
    );

    // Escolher apenas as colunas desejadas (ex: 'name' e 'email')
    const selectedData = filteredRows.map((row) => ({
      CPF: removeDotsKeepHyphen(row?.cpf),
      Agência: removeDotsKeepHyphen(row?.agencia),
      Conta: removeDotsKeepHyphen(row?.conta),
      Valor: calcularValorLiquido(row?.salario, row),
    }));

    // Transformar os dados em um formato aceito pelo XLSX
    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Colaboradores");

    // Gerar o arquivo XLS e acionar o download
    const xlsData = XLSX.write(workbook, { bookType: "xls", type: "array" });
    const blob = new Blob([xlsData], { type: "application/vnd.ms-excel" });
    const url = URL.createObjectURL(blob);

    // Criar um link temporário para download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "colaboradores_selecionados.xls");

    // Adicionar o link ao documento e acionar o download
    document.body.appendChild(link);
    link.click();

    // Limpar o link temporário
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const saveFuncionario = async (row) => {
    const initialValues = {
      nome: row?.nome,
      email: row?.email,
      telefone: row?.telefone,
      cpf: row?.cpf,
      situacao: row?.situacao,
      dataNascimento: row?.dataNascimento,
      rg: row?.rg,
      dataExpedicao: row?.dataExpedicao,
      uf: row?.uf,
      orgaoExpedidor: row?.orgaoExpedidor,
      banco: row?.banco,
      agencia: row?.agencia,
      conta: row?.conta,
      codigoBanco: row?.codigoBanco,
      chavePix: row?.chavePix,
      tipoPix: row?.tipoPix,
      pisPasep: row?.pisPasep,
      escolaridade: row?.escolaridade,
      graduacao: row?.graduacao,
      ajudaCusto: row?.ajudaCusto,
      salario: editingSalary,
      cargoId: row?.cargo?.id,
      cidadeId: row?.cidade?.id,
      tipoPagamento: row?.tipoPagamento,
      projeto: editingProjeto,
    };

    const dadosFiltrados = Object.entries(initialValues)
      .map(([key, value]) => [key, value === "" ? null : value])
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
      setLoadingSave(true);
    try {
      await api.put(`/funcionarios/${row?.id}`, {
        ...dadosFiltrados,
      });
    } catch (error) {
    } finally {
      setEditingSalaryId(null);
      setReloadColab(true);
      setLoadingSave(false);
    }
  };

  function formatToBRL(value) {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

const returnProject = () => {
  return cities?.find(
    (item) => Number(item.id) === Number(cityId)
  );
} 

  const handleProjetoChange = (event) => {
    setEditingProjeto(event.target.value);
  };


  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onSearchChange={setSearchTerm}
          downloadCSV={downloadXLS}
          user={user}
          filteredRows={visibleRows.filter((item) =>
            selected.includes(item.id)
          )}
        />

        {clients?.length <= 0 && loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
              padding: "40px",
            }}
          >
            <CircularProgress sx={{ color: "#187648" }} />
          </Box>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={visibleRows.length}
                user={user}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = selected.includes(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        {(user.typeUser === "admin" || user.typeUser === "Supervisor") && (
                          <Checkbox
                            onClick={(event) => handleClick(event, row.id)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        // sx={{ textAlign: "center" }}
                      >
                        {row.nome}
                      </TableCell>
                      <TableCell>{row.cpf}</TableCell>
                      {(user.typeUser === "admin" || user.typeUser === "Supervisor") && (
                        <>
                          <TableCell
                            sx={{
                              // textAlign: "center",
                              textTransform: "uppercase",
                            }}
                          >
                            {row.tipoPagamento}
                          </TableCell>
                          <TableCell>
                            {editingSalaryId === row.id ? (
                              <TextField
                                value={editingSalary}
                                onChange={handleSalaryChange}
                                // onBlur={() => handleSalarySave(row.id)}
                                size="small"
                                variant="outlined"
                                type="number"
                                sx={{ width: "120px" }}
                                // Adicione um `InputProps` se quiser formatar ou limitar o campo
                              />
                            ) : (
                              <span
                                onClick={() =>
                                  handleSalaryEdit(row.id, row.salario, row.projeto)
                                }
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  // justifyContent: "center",
                                }}
                              >
                                {formatToBRL(Number(row.salario))}
                                <IconButton size="small">
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              </span>
                            )}
                          </TableCell>

                          <TableCell>
                            {formatToBRL(
                              calcularValorLiquido(row.salario, row)
                            )}
                          </TableCell>
                        </>
                      )}
                      
                      <TableCell>
                            {editingSalaryId === row.id ? (
                               <select
                               onChange={handleProjetoChange}
                               className="form-control"
                               name="projeto"
                               value={editingProjeto}
                             >
                               <option value="">Selecione</option>
                               {returnProject()?.projetos?.map((proj, id) => (
                                 <option key={id} value={proj} style={{ cursor: "pointer" }}>
                                   {proj}
                                 </option>
                               ))}
                             </select>
                            ) : (
                              <span
                                onClick={() =>
                                  handleSalaryEdit(row.id, row.salario, row.projeto)
                                }
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  // justifyContent: "center",
                                }}
                              >
                                <TableCell>{row.projeto}</TableCell>
                                <IconButton size="small">
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              </span>
                            )}
                          </TableCell>
                      {editingSalaryId === row.id ? (
                        <>
                          <TableCell>
                            <div
                              style={{
                                padding: "5px",
                                border: "1px solid #dd3545",
                                textAlign: "center",
                                borderRadius: "7px",
                              }}
                              onClick={() => {
                                setEditingProjeto(null);
                                setEditingSalaryId(null);
                              }}
                              
                            >
                              Cancelar
                            </div>
                          </TableCell>

                          <TableCell>
                            <div
                              style={{
                                padding: "5px",
                                backgroundColor: "#4cca74",
                                color: "#fff",
                                textAlign: "center",
                                borderRadius: "7px",
                              }}
                              onClick={() => saveFuncionario(row)}
                            >
                              {loadingSave ? "Salvando" : "Salvar"}
                            </div>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>
                            <Modals
                              buttons={<DeleteIcon sx={{ color: "#d22c38" }} />}
                              title="APAGAR COLABORADOR"
                              id={row.id}
                              rota="funcionarios"
                              label={
                                <div>
                                  Tem certeza de que deseja apagar o colaborador{" "}
                                  <strong>{row.nome}</strong>? Ao confirmar,
                                  essa ação não poderá ser desfeita.
                                </div>
                              }
                            />
                          </TableCell>

                          <TableCell>
                            <AddUpdate Button={<EditIcon />} value={row} cities={cities} />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Box>
  );
}
